*,
:before,
:after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb
}

:before,
:after {
    --tw-content: ""
}

html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: Open Sans, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
    font-feature-settings: normal
}

body {
    margin: 0;
    line-height: inherit
}

hr {
    height: 0;
    color: inherit;
    border-top-width: 1px
}

abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit
}

a {
    color: inherit;
    text-decoration: inherit
}

b,
strong {
    font-weight: bolder
}

code,
kbd,
samp,
pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0
}

button,
select {
    text-transform: none
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button;
    /* background-color: transparent; */
    background-image: none
}

:-moz-focusring {
    outline: auto
}

:-moz-ui-invalid {
    box-shadow: none
}

progress {
    vertical-align: baseline
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

summary {
    display: list-item
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0
}

fieldset {
    margin: 0;
    padding: 0
}

legend {
    padding: 0
}

ol,
ul,
menu {
    list-style: none;
    margin: 0;
    padding: 0
}

textarea {
    resize: vertical
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    color: #9ca3af
}

input::placeholder,
textarea::placeholder {
    opacity: 1;
    color: #9ca3af
}

button,
[role=button] {
    cursor: pointer
}

:disabled {
    cursor: default
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
    vertical-align: middle
}

img,
video {
    max-width: 100%;
    height: auto
}

[hidden] {
    display: none
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

*,
:before,
:after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 640p) {
    .container {
        max-width: 640p
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
}

.fixed {
    position: fixed
}

.absolute {
    position: absolute
}

.relative {
    position: relative
}

.sticky {
    position: sticky
}

.inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
}

.-left-2 {
    left: -.5rem
}

.-top-16 {
    top: -4rem
}

.-z-10 {
    z-index: -10
}

.z-0 {
    z-index: 0
}

.z-10 {
    z-index: 10
}

.z-20 {
    z-index: 20
}

.z-50 {
    z-index: 50
}

.order-last {
    order: 9999
}

.-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem
}

.mx-3 {
    margin-left: .75rem;
    margin-right: .75rem
}

.mx-4 {
    margin-left: 1rem;
    margin-right: 1rem
}

.mx-auto {
    margin-left: auto;
    margin-right: auto
}

.my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem
}

.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
}

.-mt-12 {
    margin-top: -3rem
}

.mb-0 {
    margin-bottom: 0
}

.mb-10 {
    margin-bottom: 2.5rem
}

.mb-12 {
    margin-bottom: 3rem
}

.mb-2 {
    margin-bottom: .5rem
}

.mb-2\.5 {
    margin-bottom: .625rem
}

.mb-20 {
    margin-bottom: 5rem
}

.mb-3 {
    margin-bottom: .75rem
}

.mb-4 {
    margin-bottom: 1rem
}

.mb-5 {
    margin-bottom: 1.25rem
}

.mb-6 {
    margin-bottom: 1.5rem
}

.mb-8 {
    margin-bottom: 2rem
}

.mt-12 {
    margin-top: 3rem
}

.mt-2 {
    margin-top: .5rem
}

.mt-20 {
    margin-top: 5rem
}

.mt-3 {
    margin-top: .75rem
}

.mt-6 {
    margin-top: 1.5rem
}

.block {
    display: block
}

.inline-block {
    display: inline-block
}

.flex {
    display: flex
}

.grid {
    display: grid
}

.hidden {
    display: none
}

.h-0 {
    height: 0px
}

.h-0\.5 {
    height: .125rem
}

.h-10 {
    height: 2.5rem
}

.h-12 {
    height: 3rem
}

.h-16 {
    height: 4rem
}

.h-28 {
    height: 7rem
}

.h-3 {
    height: .75rem
}

.h-32 {
    height: 8rem
}

.h-5 {
    height: 1.25rem
}

.h-6 {
    height: 1.5rem
}

.h-7 {
    height: 1.75rem
}

.h-8 {
    height: 2rem
}

.h-\[2px\] {
    height: 2px
}

.h-auto {
    height: auto
}

.h-full {
    height: 100%
}

.h-px {
    height: 1px
}

.max-h-\[80vh\] {
    max-height: 80vh
}

.w-10 {
    width: 2.5rem
}

.w-12 {
    width: 3rem
}

.w-16 {
    width: 4rem
}

.w-28 {
    width: 7rem
}

.w-3 {
    width: .75rem
}

.w-32 {
    width: 8rem
}

.w-5 {
    width: 1.25rem
}

.w-56 {
    width: 14rem
}

.w-6 {
    width: 1.5rem
}

.w-7 {
    width: 1.75rem
}

.w-8 {
    width: 2rem
}

.w-auto {
    width: auto
}

.w-full {
    width: 100%
}

.min-w-\[3\.5rem\] {
    min-width: 3.5rem
}

.max-w-4xl {
    max-width: 56rem
}

.max-w-\[50rem\] {
    max-width: 50rem
}

.max-w-\[7rem\] {
    max-width: 7rem
}

.max-w-full {
    max-width: 100%
}

.max-w-lg {
    max-width: 32rem
}

.max-w-md {
    max-width: 28rem
}

.max-w-xl {
    max-width: 36rem
}

.flex-1 {
    flex: 1 1 0%
}

.-rotate-90 {
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-90 {
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@keyframes spin {
    to {
        transform: rotate(360deg)
    }
}

.animate-spin {
    animation: spin 1s linear infinite
}

.cursor-pointer {
    cursor: pointer
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
}

.flex-col {
    flex-direction: column
}

.flex-wrap {
    flex-wrap: wrap
}

.items-end {
    align-items: flex-end
}

.items-center {
    align-items: center
}

.justify-end {
    justify-content: flex-end
}

.justify-center {
    justify-content: center
}

.justify-between {
    justify-content: space-between
}

.gap-1 {
    gap: .25rem
}

.gap-12 {
    gap: 3rem
}

.gap-16 {
    gap: 4rem
}

.gap-2 {
    gap: .5rem
}

.gap-20 {
    gap: 5rem
}

.gap-4 {
    gap: 1rem
}

.gap-5 {
    gap: 1.25rem
}

.gap-6 {
    gap: 1.5rem
}

.gap-8 {
    gap: 2rem
}

.gap-9 {
    gap: 2.25rem
}

.space-y-16>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse))
}

.overflow-hidden {
    overflow: hidden
}

.overflow-x-auto {
    overflow-x: auto
}

.overflow-y-auto {
    overflow-y: auto
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.whitespace-nowrap {
    white-space: nowrap
}

.rounded-2xl {
    border-radius: 1rem
}

.rounded-3xl {
    border-radius: 1.5rem
}

.rounded-full {
    border-radius: 9999px
}

.rounded-lg {
    border-radius: .5rem
}

.rounded-xl {
    border-radius: .75rem
}

.rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.border {
    border-width: 1px
}

.border-2 {
    border-width: 2px
}

.border-t-2 {
    border-top-width: 2px
}

.border-dashed {
    border-style: dashed
}

.\!border-red-500\/50 {
    border-color: #ef444480 !important
}

.border-transparent {
    border-color: transparent
}

.border-white\/10 {
    border-color: #ffffff1a
}

.border-white\/20 {
    border-color: #fff3
}

.bg-\[\#131212\]\/70 {
    background-color: #131212b3
}

.bg-\[\#14181C\]\/30 {
    background-color: #14181c4d
}

.bg-\[\#14181C\]\/60 {
    background-color: #14181c99
}

.bg-\[\#1A2025\]\/70 {
    background-color: #1a2025b3
}

.bg-\[\#1B0432\]\/80 {
    background-color: #1b0432cc
}

.bg-\[\#232C3A\]\/50,
.bg-\[\#232c3a\]\/50 {
    background-color: #232c3a80
}

.bg-\[\#243192\] {
    --tw-bg-opacity: 1;
    background-color: rgb(36 49 146 / var(--tw-bg-opacity))
}

.bg-\[\#2F3B4F\] {
    --tw-bg-opacity: 1;
    background-color: rgb(47 59 79 / var(--tw-bg-opacity))
}

.bg-\[\#2F3B4F\]\/50 {
    background-color: #2f3b4f80
}

.bg-\[\#30353A\] {
    --tw-bg-opacity: 1;
    background-color: rgb(48 53 58 / var(--tw-bg-opacity))
}

.bg-\[\#4B0491\] {
    --tw-bg-opacity: 1;
    background-color: rgb(75 4 145 / var(--tw-bg-opacity))
}

.bg-\[\#76FF9C\] {
    --tw-bg-opacity: 1;
    background-color: rgb(118 255 156 / var(--tw-bg-opacity))
}

.bg-\[\#A78DF5\] {
    --tw-bg-opacity: 1;
    background-color: rgb(167 141 245 / var(--tw-bg-opacity))
}

.bg-\[\#ED7789\] {
    --tw-bg-opacity: 1;
    background-color: rgb(237 119 137 / var(--tw-bg-opacity))
}

.bg-\[\#F5DC87\] {
    --tw-bg-opacity: 1;
    background-color: rgb(245 220 135 / var(--tw-bg-opacity))
}

.bg-\[\#FFA723\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 167 35 / var(--tw-bg-opacity))
}

.bg-black\/10 {
    background-color: #0000001a
}

.bg-black\/50 {
    background-color: #00000080
}

.bg-black\/60 {
    background-color: #0009
}

.bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(74 222 128 / var(--tw-bg-opacity))
}

.bg-primary {
    --tw-bg-opacity: 1;
    background-color: rgb(243 32 216 / var(--tw-bg-opacity))
}

.bg-transparent {
    background-color: transparent
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-white\/10 {
    background-color: #ffffff1a
}

.bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
}

.bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops))
}

.bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops))
}

.bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops))
}

.from-\[\#16A6EE\] {
    --tw-gradient-from: #16A6EE;
    --tw-gradient-to: rgb(22 166 238 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-\[\#F320D8\] {
    --tw-gradient-from: #0e57bd;
    --tw-gradient-to: rgb(243 32 216 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-black\/0 {
    --tw-gradient-from: rgb(0 0 0 / 0);
    --tw-gradient-to: rgb(0 0 0 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-white\/0 {
    --tw-gradient-from: rgb(255 255 255 / 0);
    --tw-gradient-to: rgb(255 255 255 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-white\/20 {
    --tw-gradient-from: rgb(255 255 255 / .2);
    --tw-gradient-to: rgb(255 255 255 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.to-\[\#16A6EE\] {
    --tw-gradient-to: #16A6EE
}

.to-\[\#4B0491\] {
    --tw-gradient-to: #00969d
}

.to-\[\#F320D8\] {
    --tw-gradient-to: #00969d
}

.to-white\/0 {
    --tw-gradient-to: rgb(255 255 255 / 0)
}

.to-white\/20 {
    --tw-gradient-to: rgb(255 255 255 / .2)
}

.bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text
}

.fill-primary {
    fill:#0e57bd
}

.object-contain {
    -o-object-fit: contain;
    object-fit: contain
}

.p-1 {
    padding: .25rem
}

.p-10 {
    padding: 2.5rem
}

.p-3 {
    padding: .75rem
}

.p-6 {
    padding: 1.5rem
}

.p-8 {
    padding: 2rem
}

.px-0 {
    padding-left: 0;
    padding-right: 0
}

.px-1 {
    padding-left: .25rem;
    padding-right: .25rem
}

.px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem
}

.px-2 {
    padding-left: .5rem;
    padding-right: .5rem
}

.px-3 {
    padding-left: .75rem;
    padding-right: .75rem
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}

.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
}

.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.px-8 {
    padding-left: 2rem;
    padding-right: 2rem
}

.py-0 {
    padding-top: 0;
    padding-bottom: 0
}

.py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem
}

.py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem
}

.py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem
}

.py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem
}

.py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem
}

.py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
}

.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}

.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
}

.pb-8 {
    padding-bottom: 2rem
}

.pl-2 {
    padding-left: .5rem
}

.pl-2\.5 {
    padding-left: .625rem
}

.pl-6 {
    padding-left: 1.5rem
}

.pr-2 {
    padding-right: .5rem
}

.pr-2\.5 {
    padding-right: .625rem
}

.pr-4 {
    padding-right: 1rem
}

.pr-6 {
    padding-right: 1.5rem
}

.pt-12 {
    padding-top: 3rem
}

.pt-20 {
    padding-top: 5rem
}

.pt-8 {
    padding-top: 2rem
}

.text-center {
    text-align: center
}

.font-chakra {
    font-family: Chakra Petch, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji"
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem
}

.text-5xl {
    font-size: 3rem;
    line-height: 1
}

.text-base {
    font-size: 1rem;
    line-height: 1.5rem
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
}

.text-sm {
    font-size: .875rem;
    line-height: 1.25rem
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
}

.text-xs {
    font-size: .75rem;
    line-height: 1rem
}

.font-bold {
    font-weight: 700
}

.font-light {
    font-weight: 300
}

.font-medium {
    font-weight: 500
}

.font-normal {
    font-weight: 400
}

.font-semibold {
    font-weight: 600
}

.uppercase {
    text-transform: uppercase
}

.leading-normal {
    line-height: 1.5
}

.leading-relaxed {
    line-height: 1.625
}

.leading-snug {
    line-height: 1.375
}

.\!text-red-400 {
    --tw-text-opacity: 1 !important;
    color: rgb(248 113 113 / var(--tw-text-opacity)) !important
}

.text-\[\#F320D8\] {
    --tw-text-opacity: 1;
    color: rgb(243 32 216 / var(--tw-text-opacity))
}

.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity))
}

.text-gray-300 {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity))
}

.text-primary {
    --tw-text-opacity: 1;
    color: rgb(243 32 216 / var(--tw-text-opacity))
}

.text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity))
}

.text-slate-500 {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity))
}

.text-transparent {
    color: transparent
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.text-white\/50 {
    color: #ffffff80
}

.text-white\/80 {
    color: #fffc
}

.text-white\/90 {
    color: #ffffffe6
}

.underline {
    text-decoration-line: underline
}

.opacity-50 {
    opacity: .5
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
}

.ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.\!ring-red-500\/20 {
    --tw-ring-color: rgb(239 68 68 / .2) !important
}

.ring-primary\/30 {
    --tw-ring-color: rgb(243 32 216 / .3)
}

.ring-transparent {
    --tw-ring-color: transparent
}

.blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.duration-200 {
    transition-duration: .2s
}

.duration-300 {
    transition-duration: .3s
}

html {
    font-size: 16px;
    scroll-behavior: smooth
}

body {
    overflow-x: hidden
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Chakra Petch, sans-serif
}

.text-gradient {
    background: linear-gradient(#00969d 0%, #16a6ee 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    background-color: #0f0e13;
    background-image: url("../img/bg.png");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover
}

.swiper-wrapper {
    align-items: center
}

@media (min-width: 1024px) {
    .swiper-wrapper {
        justify-content: space-between
    }
}

.gradient-border {
    position: relative
}

.gradient-border:before {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border-radius: 1rem;
    padding: .125rem;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .3s;
    content: "";
    background: linear-gradient(160deg, #3e1f5e 20%, rgba(255, 255, 255, .3) 50%, rgba(255, 255, 255, 0) 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude
}

.gradient-border:hover:before {
    padding: .25rem;
    background: linear-gradient(160deg, #3e1f5e 0%, #3e1f5e 100%)
}

.before\:absolute:before {
    content: var(--tw-content);
    position: absolute
}

.before\:-top-\[58px\]:before {
    content: var(--tw-content);
    top: -58px
}

.before\:h-2:before {
    content: var(--tw-content);
    height: .5rem
}

.before\:h-2\.5:before {
    content: var(--tw-content);
    height: .625rem
}

.before\:w-full:before {
    content: var(--tw-content);
    width: 100%
}

.before\:rounded-full:before {
    content: var(--tw-content);
    border-radius: 9999px
}

.before\:bg-primary:before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(243 32 216 / var(--tw-bg-opacity))
}

.before\:bg-white\/50:before {
    content: var(--tw-content);
    background-color: #ffffff80
}

.last\:pr-0:last-child {
    padding-right: 0
}

.focus-within\:border-primary\/50:focus-within {
    border-color: #f320d880
}

.focus-within\:ring-primary\/20:focus-within {
    --tw-ring-color: rgb(243 32 216 / .2)
}

.hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.hover\:border-solid:hover {
    border-style: solid
}

.hover\:border-primary:hover {
    --tw-border-opacity: 1;
    border-color: rgb(243 32 216 / var(--tw-border-opacity))
}

.hover\:bg-primary:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(243 32 216 / var(--tw-bg-opacity))
}

.hover\:bg-primary\/50:hover {
    background-color: #f320d880
}

.hover\:opacity-75:hover {
    opacity: .75
}

.hover\:opacity-80:hover {
    opacity: .8
}

.disabled\:cursor-not-allowed:disabled {
    cursor: not-allowed
}

.disabled\:from-gray-400:disabled {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-to: rgb(156 163 175 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.disabled\:opacity-80:disabled {
    opacity: .8
}

.group:hover .group-hover\:translate-x-2 {
    --tw-translate-x: .5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@media (prefers-color-scheme: dark) {
    .dark\:text-slate-200 {
        --tw-text-opacity: 1;
        color: rgb(226 232 240 / var(--tw-text-opacity))
    }
}

@media (min-width: 1024px) {
    .lg\:order-first {
        order: -9999
    }

    .lg\:mx-0 {
        margin-left: 0;
        margin-right: 0
    }

    .lg\:mx-4 {
        margin-left: 1rem;
        margin-right: 1rem
    }

    .lg\:-mt-24 {
        margin-top: -6rem
    }

    .lg\:mb-12 {
        margin-bottom: 3rem
    }

    .lg\:mb-24 {
        margin-bottom: 6rem
    }

    .lg\:mb-8 {
        margin-bottom: 2rem
    }

    .lg\:mt-24 {
        margin-top: 6rem
    }

    .lg\:block {
        display: block
    }

    .lg\:inline {
        display: inline
    }

    .lg\:flex {
        display: flex
    }

    .lg\:h-16 {
        height: 4rem
    }

    .lg\:h-20 {
        height: 5rem
    }

    .lg\:h-32 {
        height: 8rem
    }

    .lg\:h-56 {
        height: 14rem
    }

    .lg\:w-1\/2 {
        width: 50%
    }

    .lg\:w-1\/4 {
        width: 25%
    }

    .lg\:w-20 {
        width: 5rem
    }

    .lg\:w-32 {
        width: 8rem
    }

    .lg\:w-5\/12 {
        width: 41.666667%
    }

    .lg\:w-56 {
        width: 14rem
    }

    .lg\:w-7\/12 {
        width: 58.333333%
    }

    .lg\:min-w-\[5rem\] {
        min-width: 5rem
    }

    .lg\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .lg\:flex-row {
        flex-direction: row
    }

    .lg\:justify-start {
        justify-content: flex-start
    }

    .lg\:gap-12 {
        gap: 3rem
    }

    .lg\:gap-16 {
        gap: 4rem
    }

    .lg\:gap-3 {
        gap: .75rem
    }

    .lg\:gap-32 {
        gap: 8rem
    }

    .lg\:gap-4 {
        gap: 1rem
    }

    .lg\:gap-6 {
        gap: 1.5rem
    }

    .lg\:whitespace-normal {
        white-space: normal
    }

    .lg\:p-8 {
        padding: 2rem
    }

    .lg\:px-0 {
        padding-left: 0;
        padding-right: 0
    }

    .lg\:px-12 {
        padding-left: 3rem;
        padding-right: 3rem
    }

    .lg\:py-12 {
        padding-top: 3rem;
        padding-bottom: 3rem
    }

    .lg\:py-16 {
        padding-top: 4rem;
        padding-bottom: 4rem
    }

    .lg\:py-24 {
        padding-top: 6rem;
        padding-bottom: 6rem
    }

    .lg\:py-4 {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .lg\:py-6 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem
    }

    .lg\:pb-16 {
        padding-bottom: 4rem
    }

    .lg\:text-left {
        text-align: left
    }

    .lg\:text-2xl {
        font-size: 1.5rem;
        line-height: 2rem
    }

    .lg\:text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem
    }

    .lg\:text-4xl {
        font-size: 2.25rem;
        line-height: 2.5rem
    }

    .lg\:text-5xl {
        font-size: 3rem;
        line-height: 1
    }

    .lg\:text-base {
        font-size: 1rem;
        line-height: 1.5rem
    }

    .lg\:text-lg {
        font-size: 1.125rem;
        line-height: 1.75rem
    }

    .lg\:text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem
    }
}

@media (min-width: 1280px) {
    .xl\:mb-16 {
        margin-bottom: 4rem
    }

    .xl\:text-4xl {
        font-size: 2.25rem;
        line-height: 2.5rem
    }
}

:root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #07bc0c;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, .7);
    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);
    --toastify-toast-width: 320px;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;
    --toastify-text-color-light: #757575;
    --toastify-text-color-dark: #fff;
    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;
    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;
    --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error)
}

.Toastify__toast-container {
    z-index: var(--toastify-z-index);
    -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
    position: fixed;
    padding: 4px;
    width: var(--toastify-toast-width);
    box-sizing: border-box;
    color: #fff
}

.Toastify__toast-container--top-left {
    top: 1em;
    left: 1em
}

.Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    transform: translate(-50%)
}

.Toastify__toast-container--top-right {
    top: 1em;
    right: 1em
}

.Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 1em
}

.Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    transform: translate(-50%)
}

.Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em
}

@media only screen and (max-width : 480px) {
    .Toastify__toast-container {
        width: 100vw;
        padding: 0;
        left: 0;
        margin: 0
    }

    .Toastify__toast-container--top-left,
    .Toastify__toast-container--top-center,
    .Toastify__toast-container--top-right {
        top: 0;
        transform: translate(0)
    }

    .Toastify__toast-container--bottom-left,
    .Toastify__toast-container--bottom-center,
    .Toastify__toast-container--bottom-right {
        bottom: 0;
        transform: translate(0)
    }

    .Toastify__toast-container--rtl {
        right: 0;
        left: initial
    }
}

.Toastify__toast {
    position: relative;
    min-height: var(--toastify-toast-min-height);
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
    display: flex;
    justify-content: space-between;
    max-height: var(--toastify-toast-max-height);
    overflow: hidden;
    font-family: var(--toastify-font-family);
    cursor: default;
    direction: ltr;
    z-index: 0
}

.Toastify__toast--rtl {
    direction: rtl
}

.Toastify__toast--close-on-click {
    cursor: pointer
}

.Toastify__toast-body {
    margin: auto 0;
    flex: 1 1 auto;
    padding: 6px;
    display: flex;
    align-items: center
}

.Toastify__toast-body>div:last-child {
    word-break: break-word;
    flex: 1
}

.Toastify__toast-icon {
    -webkit-margin-end: 10px;
    margin-inline-end: 10px;
    width: 20px;
    flex-shrink: 0;
    display: flex
}

.Toastify--animate {
    animation-fill-mode: both;
    animation-duration: .7s
}

.Toastify--animate-icon {
    animation-fill-mode: both;
    animation-duration: .3s
}

@media only screen and (max-width : 480px) {
    .Toastify__toast {
        margin-bottom: 0;
        border-radius: 0
    }
}

.Toastify__toast-theme--dark {
    background: var(--toastify-color-dark);
    color: var(--toastify-text-color-dark)
}

.Toastify__toast-theme--light,
.Toastify__toast-theme--colored.Toastify__toast--default {
    background: var(--toastify-color-light);
    color: var(--toastify-text-color-light)
}

.Toastify__toast-theme--colored.Toastify__toast--info {
    color: var(--toastify-text-color-info);
    background: var(--toastify-color-info)
}

.Toastify__toast-theme--colored.Toastify__toast--success {
    color: var(--toastify-text-color-success);
    background: var(--toastify-color-success)
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
    color: var(--toastify-text-color-warning);
    background: var(--toastify-color-warning)
}

.Toastify__toast-theme--colored.Toastify__toast--error {
    color: var(--toastify-text-color-error);
    background: var(--toastify-color-error)
}

.Toastify__progress-bar-theme--light {
    background: var(--toastify-color-progress-light)
}

.Toastify__progress-bar-theme--dark {
    background: var(--toastify-color-progress-dark)
}

.Toastify__progress-bar--info {
    background: var(--toastify-color-progress-info)
}

.Toastify__progress-bar--success {
    background: var(--toastify-color-progress-success)
}

.Toastify__progress-bar--warning {
    background: var(--toastify-color-progress-warning)
}

.Toastify__progress-bar--error {
    background: var(--toastify-color-progress-error)
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
    background: var(--toastify-color-transparent)
}

.Toastify__close-button {
    color: #fff;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: .7;
    transition: .3s ease;
    align-self: flex-start
}

.Toastify__close-button--light {
    color: #000;
    opacity: .3
}

.Toastify__close-button>svg {
    fill: currentColor;
    height: 16px;
    width: 14px
}

.Toastify__close-button:hover,
.Toastify__close-button:focus {
    opacity: 1
}

@keyframes Toastify__trackProgress {
    0% {
        transform: scaleX(1)
    }

    to {
        transform: scaleX(0)
    }
}

.Toastify__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: var(--toastify-z-index);
    opacity: .7;
    transform-origin: left
}

.Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards
}

.Toastify__progress-bar--controlled {
    transition: transform .2s
}

.Toastify__progress-bar--rtl {
    right: 0;
    left: initial;
    transform-origin: right
}

.Toastify__spinner {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border: 2px solid;
    border-radius: 100%;
    border-color: var(--toastify-spinner-color-empty-area);
    border-right-color: var(--toastify-spinner-color);
    animation: Toastify__spin .65s linear infinite
}

@keyframes Toastify__bounceInRight {

    0%,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        transform: translate3d(3000px, 0, 0)
    }

    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0)
    }

    75% {
        transform: translate3d(10px, 0, 0)
    }

    90% {
        transform: translate3d(-5px, 0, 0)
    }

    to {
        transform: none
    }
}

@keyframes Toastify__bounceOutRight {
    20% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0)
    }

    to {
        opacity: 0;
        transform: translate3d(2000px, 0, 0)
    }
}

@keyframes Toastify__bounceInLeft {

    0%,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        transform: translate3d(-3000px, 0, 0)
    }

    60% {
        opacity: 1;
        transform: translate3d(25px, 0, 0)
    }

    75% {
        transform: translate3d(-10px, 0, 0)
    }

    90% {
        transform: translate3d(5px, 0, 0)
    }

    to {
        transform: none
    }
}

@keyframes Toastify__bounceOutLeft {
    20% {
        opacity: 1;
        transform: translate3d(20px, 0, 0)
    }

    to {
        opacity: 0;
        transform: translate3d(-2000px, 0, 0)
    }
}

@keyframes Toastify__bounceInUp {

    0%,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        transform: translate3d(0, 3000px, 0)
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0)
    }

    75% {
        transform: translate3d(0, 10px, 0)
    }

    90% {
        transform: translate3d(0, -5px, 0)
    }

    to {
        transform: translateZ(0)
    }
}

@keyframes Toastify__bounceOutUp {
    20% {
        transform: translate3d(0, -10px, 0)
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, 20px, 0)
    }

    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0)
    }
}

@keyframes Toastify__bounceInDown {

    0%,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0)
    }

    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0)
    }

    75% {
        transform: translate3d(0, -10px, 0)
    }

    90% {
        transform: translate3d(0, 5px, 0)
    }

    to {
        transform: none
    }
}

@keyframes Toastify__bounceOutDown {
    20% {
        transform: translate3d(0, 10px, 0)
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, -20px, 0)
    }

    to {
        opacity: 0;
        transform: translate3d(0, 2000px, 0)
    }
}

.Toastify__bounce-enter--top-left,
.Toastify__bounce-enter--bottom-left {
    animation-name: Toastify__bounceInLeft
}

.Toastify__bounce-enter--top-right,
.Toastify__bounce-enter--bottom-right {
    animation-name: Toastify__bounceInRight
}

.Toastify__bounce-enter--top-center {
    animation-name: Toastify__bounceInDown
}

.Toastify__bounce-enter--bottom-center {
    animation-name: Toastify__bounceInUp
}

.Toastify__bounce-exit--top-left,
.Toastify__bounce-exit--bottom-left {
    animation-name: Toastify__bounceOutLeft
}

.Toastify__bounce-exit--top-right,
.Toastify__bounce-exit--bottom-right {
    animation-name: Toastify__bounceOutRight
}

.Toastify__bounce-exit--top-center {
    animation-name: Toastify__bounceOutUp
}

.Toastify__bounce-exit--bottom-center {
    animation-name: Toastify__bounceOutDown
}

@keyframes Toastify__zoomIn {
    0% {
        opacity: 0;
        transform: scale3d(.3, .3, .3)
    }

    50% {
        opacity: 1
    }
}

@keyframes Toastify__zoomOut {
    0% {
        opacity: 1
    }

    50% {
        opacity: 0;
        transform: scale3d(.3, .3, .3)
    }

    to {
        opacity: 0
    }
}

.Toastify__zoom-enter {
    animation-name: Toastify__zoomIn
}

.Toastify__zoom-exit {
    animation-name: Toastify__zoomOut
}

@keyframes Toastify__flipIn {
    0% {
        transform: perspective(400px) rotateX(90deg);
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        transform: perspective(400px) rotateX(-20deg);
        animation-timing-function: ease-in
    }

    60% {
        transform: perspective(400px) rotateX(10deg);
        opacity: 1
    }

    80% {
        transform: perspective(400px) rotateX(-5deg)
    }

    to {
        transform: perspective(400px)
    }
}

@keyframes Toastify__flipOut {
    0% {
        transform: perspective(400px)
    }

    30% {
        transform: perspective(400px) rotateX(-20deg);
        opacity: 1
    }

    to {
        transform: perspective(400px) rotateX(90deg);
        opacity: 0
    }
}

.Toastify__flip-enter {
    animation-name: Toastify__flipIn
}

.Toastify__flip-exit {
    animation-name: Toastify__flipOut
}

@keyframes Toastify__slideInRight {
    0% {
        transform: translate3d(110%, 0, 0);
        visibility: visible
    }

    to {
        transform: translateZ(0)
    }
}

@keyframes Toastify__slideInLeft {
    0% {
        transform: translate3d(-110%, 0, 0);
        visibility: visible
    }

    to {
        transform: translateZ(0)
    }
}

@keyframes Toastify__slideInUp {
    0% {
        transform: translate3d(0, 110%, 0);
        visibility: visible
    }

    to {
        transform: translateZ(0)
    }
}

@keyframes Toastify__slideInDown {
    0% {
        transform: translate3d(0, -110%, 0);
        visibility: visible
    }

    to {
        transform: translateZ(0)
    }
}

@keyframes Toastify__slideOutRight {
    0% {
        transform: translateZ(0)
    }

    to {
        visibility: hidden;
        transform: translate3d(110%, 0, 0)
    }
}

@keyframes Toastify__slideOutLeft {
    0% {
        transform: translateZ(0)
    }

    to {
        visibility: hidden;
        transform: translate3d(-110%, 0, 0)
    }
}

@keyframes Toastify__slideOutDown {
    0% {
        transform: translateZ(0)
    }

    to {
        visibility: hidden;
        transform: translate3d(0, 500px, 0)
    }
}

@keyframes Toastify__slideOutUp {
    0% {
        transform: translateZ(0)
    }

    to {
        visibility: hidden;
        transform: translate3d(0, -500px, 0)
    }
}

.Toastify__slide-enter--top-left,
.Toastify__slide-enter--bottom-left {
    animation-name: Toastify__slideInLeft
}

.Toastify__slide-enter--top-right,
.Toastify__slide-enter--bottom-right {
    animation-name: Toastify__slideInRight
}

.Toastify__slide-enter--top-center {
    animation-name: Toastify__slideInDown
}

.Toastify__slide-enter--bottom-center {
    animation-name: Toastify__slideInUp
}

.Toastify__slide-exit--top-left,
.Toastify__slide-exit--bottom-left {
    animation-name: Toastify__slideOutLeft
}

.Toastify__slide-exit--top-right,
.Toastify__slide-exit--bottom-right {
    animation-name: Toastify__slideOutRight
}

.Toastify__slide-exit--top-center {
    animation-name: Toastify__slideOutUp
}

.Toastify__slide-exit--bottom-center {
    animation-name: Toastify__slideOutDown
}

@keyframes Toastify__spin {
    0% {
        transform: rotate(0)
    }

    to {
        transform: rotate(360deg)
    }
}